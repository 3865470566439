<template>
    <div>
        <AddApprentice />
        <RecruitmentFormApprentice />
        <Tutor />
        <ApprenticeTable />
    </div>
</template>

<script>
import AddApprentice from '@/components/molecules/AddApprentice.vue';
import RecruitmentFormApprentice from '@/components/molecules/RecruitmentFormApprentice.vue';
import Tutor from '@/components/molecules/Tutor.vue';
import ApprenticeTable from '@/components/templates/ApprenticeTable';

export default {
    components : {
        ApprenticeTable,
        AddApprentice,
		RecruitmentFormApprentice,
        Tutor
    }
}
</script>

<style>

</style>