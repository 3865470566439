<template>
    <v-container class="my-2 text-left">
        <v-btn
            color="blue"
            dark
            medium
            v-if="currentRole == 'administrator' || currentRole == 'moderator'"
            @click="show = !show"
        >
            Opiekun praktyk: {{tutor}}
        </v-btn>

        <v-dialog v-model="show" max-width="1000px">
            <v-card>
                <v-toolbar class="indigo" dark flat>
                    <v-toolbar-title>Ustaw opiekuna praktyk</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            label="Email"
                            name="email"
                            type="text"
                            v-model="email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()" 
                            
                        />
                        <p v-if="feedback">{{ feedback }}</p>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="ma-2"
                        :disabled="$v.$invalid"
                        color="secondary"
                        @click="add"
						:loading="isLoaded"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';
import io from 'socket.io-client';

export default{
    name: 'AddTutor',
    mixins: [validationMixin, errorMsg],
    computed: {
        currentRole(){
            return this.$store.getters.getUserRole;
        },
    },

    data(){
        return{
			isLoaded: false,
            loader: null,
            email: null,
            feedback: null,
            show: false,
            tutor: '',
            socket: io(this.url),
        }
    },
    validations: {
        email: {
            required,
            email
        },
    },
    methods: {
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        add(){
            if(!this.$v.$invalid){
				this.isLoaded = true
                this.$axios.put(`${this.url}api/users/practice-tutor`, {
                    email: this.email,
                    oldEmail:this.tutor
                }).then(()=>{
					this.isLoaded = false;
                    this.resetValidation()
                    this.show = false
                }).catch(()=>{
					this.isLoaded = false;
				})
            }
        },
        get(){
            this.$axios.get(`${this.url}api/users/practice-tutor`)
            .then((el)=>{
                this.tutor = el.data.email
            }).catch(()=>{
                this.isLoaded = false;
            })
        },

    },
    created(){
        this.get();
        this.socket.on('tutorSet', () => {
            this.get();
        })
    },
    }
</script>
